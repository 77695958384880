<template>
  <div>
    <van-cell title="程序下载">
      <template #right-icon>
        <van-checkbox v-model="zrd001_Step1.chengXuXiaZai" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="检测按键">
      <template #right-icon>
        <van-checkbox v-model="zrd001_Step1.jianCeAnJian" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="检测屏幕显示">
      <template #right-icon>
        <van-checkbox v-model="zrd001_Step1.jianCePingMu" shape="square"></van-checkbox>
      </template>
    </van-cell>
      <van-cell title="检测系统时间运行">
      <template #right-icon>
        <van-checkbox v-model="zrd001_Step1.jianCeXiTongShiJian" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-field label="备注" v-model="zrd001_Step1.remark"> </van-field>
    <div class="footer">
      <van-button type="info" v-show="$parent.hasPower_Step1" :disabled="$parent.zrd001_SignNumber.status!=0" round block @click="save_ZRD001_Step1">保存</van-button>
      <van-button type="danger" v-if="$parent.zrd001_SignNumber.status==2" round block @click="$parent.cancel_void_SignNumber()">取消作废</van-button>
      <van-button type="danger" v-else-if="$parent.zrd001_SignNumber.status!=1" round block @click="$parent.void_SignNumber()">作废</van-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["signNumber"],
  data() {
    return {
      zrd001_Step1: {},
    };
  },
  methods: {
    getZRD001_Step1() {
      let that = this;
      that.axios
        .post("/ZRD001/GetZRD001_Step1", { onlyText: that.signNumber })
        .then(function (response) {
          that.zrd001_Step1 = response.data.data;
        });
    },
    save_ZRD001_Step1() {
      let that = this;
      that.axios
        .post("ZRD001/UpdateZRD001_Step1", that.zrd001_Step1)
        .then(function (response) {
          that.zrd001_Step1 = response.data.data;
          that.$notify({ type: "success", message: response.data.msg });
        });
    },
  },
  mounted() {
    let that = this;
    that.getZRD001_Step1();
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
</style>